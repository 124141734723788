@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Mulish", sans-serif;
}

body {
    max-width: 1920px;
    margin: 0 auto;
    background-color: #000;
}

.bg_color {
    background: radial-gradient(107.32% 141.42% at 0 0, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: -5px -5px 250px 0 rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(21px);
}

.scroll_up {
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5);
}

.contacts_form form {
    display: grid;
}

.contacts_form div {
    padding: 0;
}